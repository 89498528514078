// function trackEvent(event) {
window.trackEvent = function trackEvent(event) {
  mixpanel.track(event)
}

// function calculateForm() {
window.calculateForm = function calculateForm() {
  return {
    // default properties
    showMobile: false,
    ctaActive: false,
    emailMessage: false,
    emailSubmitted: false,
    meetingCost: '$0',
    meetingTimes: [
      {
        num: .25,
        label: '15 Minutes'
      },{
        num: .5,
        label: '1/2 Hour'
      },{
        num: 1,
        label: '1 Hour'
      },{
        num: 1.5,
        label: '90 Minutes'
      },{
        num: 2,
        label: '2 Hrs'
      },{
        num: 4,
        label: '4 Hrs'
      },{
        num: 8,
        label: '8 Hrs'
      }
    ],
    selectedTime: 1,
    defaultTimeIndex: 2,
    basicForm: {
      attendees: '',
      salary: '',
      hours: 36.4, /* 36.4: https://clockify.me/working-hours; 37.7: https://www150.statcan.gc.ca/n1/pub/14-28-0001/2023001/article/00001-eng.htm */
      hoursPerYear: 1892, /* https://clockify.me/working-hours */
      weeksPerYear: 48 /* Excluding Federal Holidays & 10-14 PTO Days; https://buildremote.co/management/how-many-work-weeks-in-a-year/ */
    },
    advancedForm: {
      attendees: '',
      hours: 36.4,
      weeks: 48,
      salary: '',
      salaries: []
    },
    // functions
    calculateBasicForm() {
      trackEvent('Submited Basic Form');
      if (this.basicForm.attendees === '' || this.basicForm.attendees === 0) {
        alert('You haven\'t told us how many people are attending this meeting.');
      } else if (this.basicForm.salary === '' || this.basicForm.salary === 0) {
        alert('You haven\'t provided the average annual salary of the attendees of this meeting');
      } else {
        const salary_num = parseFloat(this.basicForm.salary.replace(/[^\d.-]/g, ''));
        const cost = this.basicForm.attendees * salary_num / this.basicForm.hoursPerYear;
        this.meetingCost = cost.toFixed(2);
        // convert to currency
        let USDollar = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        if (this.selectedTime < 1) {
          this.meetingCost = USDollar.format(this.meetingCost / this.selectedTime);
        } else if (this.selectTime > 1) {
          this.meetingCost = USDollar.format(this.meetingCost * this.selectedTime);
        } else {
          this.meetingCost = USDollar.format(this.meetingCost);
        }
        // display email capture
        this.emailMessage = true;
        // display resultsArea on mobile;
        this.showMobile = true;
        trackEvent('Received Basic Meeting Cost');
      }
    },
    addSalary() {
      if (this.advancedForm.salary === '') {
        alert('You haven\'t provided a salary to add.');
      } else {
        this.advancedForm.salaries.push(this.advancedForm.salary);
        this.advancedForm.salary = '';
      }
    },
    removeSalary(index) {
      this.advancedForm.salaries.splice(index);
    },
    calculateAdvancedForm() {
      trackEvent('Submited Advanced Form');
      if (this.advancedForm.salary !== '') {
        this.advancedForm.salaries.push(this.advancedForm.salary);
        this.advancedForm.salary = '';
      }
      //
      if (this.advancedForm.attendees === '' || this.advancedForm.attendees === 0) {
        alert('You haven\'t told us how many people are attending this meeting.');
      } else if (this.advancedForm.hours === '' || this.advancedForm.hours === 0) {
        alert('You haven\'t told the average number of hours per week that are worked.');
      } else if (this.advancedForm.weeks === '' || this.advancedForm.weeks === 0) {
        alert('You haven\'t told the average number of weeks in a year that are worked.');
      } else if (this.advancedForm.salaries.length !== parseFloat(this.advancedForm.attendees)) {
        alert('You haven\'t provided a salary for all attendees.');
      } else {
        // loop through this.advancedForm.salaries, calculate a cost per salary then add them up
        let totalCost = 0;
        this.advancedForm.salaries.forEach((salary) => {
          const salary_num = parseFloat(salary.replace(/[^\d.-]/g, ''));
          const hoursPerYear = Math.round(this.advancedForm.hours * this.advancedForm.weeks);
          const cost = this.advancedForm.attendees * salary_num / hoursPerYear;
          totalCost = totalCost + cost;
        });
        totalCost = totalCost.toFixed(2);
        // convert to currency
        let USDollar = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        if (this.selectedTime < 1) {
          this.meetingCost = USDollar.format(this.totalCost / this.selectedTime);
        } else if (this.selectTime > 1) {
          this.meetingCost = USDollar.format(this.totalCost * this.selectedTime);
        } else {
          this.meetingCost = USDollar.format(this.totalCost);
        }
        // display email capture
        this.emailMessage = true;
        // display resultsArea on mobile;
        this.showMobile = true;
        trackEvent('Received Advanced Meeting Cost');
      }
    }
  }
}

// function betaSignup() {
window.betaSignup = function betaSignup() {
  return {
    loading: false,
    status: '',
    async submit(event) {
      let formData = new FormData(event.target);
      let object = Object.fromEntries(formData);
      const json = JSON.stringify(object);

      this.loading = true;

      const response = await fetch("https://formbold.com/s/3wkx4", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });
      const result = await response.json();

      if (response.ok) {
        this.status = result.message || "Success";
        this.emailSubmitted = true;
        this.emailCaptureSubmitted = true;
        trackEvent('Beta Signup');
        event.target.reset();
      }
      this.status = result.message || "Something went wrong";
      trackEvent('Failed Beta Signup');
      this.loading = false;
      setTimeout(() => (this.status = ""), 3000);
    }
  }
}
